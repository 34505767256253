import { t } from 'i18next';

import { SOCIAL_LINKS } from '~screens/Dashboard/components/Footer/constants';

import { BASE_URL, CURRENT_COUNTRY_ID, TRANSLATION_KEY } from './environment';
import { logoImage, logoImagePt } from './assets';
import { emails } from './contact';

const logo = {
  pt: logoImagePt,
  default: logoImage
};

export function getStructuredDataOrg() {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Papa Johns',
    url: BASE_URL,
    logo: {
      '@type': 'ImageObject',
      url: `${BASE_URL}${logo[CURRENT_COUNTRY_ID] || logo.default}`,
      width: 190,
      height: 50
    },
    image: `${BASE_URL}${logo[CURRENT_COUNTRY_ID] || logo.default}`,
    description: t('App:SEOdescription'),
    sameAs: Object.entries(SOCIAL_LINKS).map(([, link]) => link),
    contactPoint: [
      {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        email: emails[CURRENT_COUNTRY_ID],
        areaServed: [CURRENT_COUNTRY_ID],
        availableLanguage: [TRANSLATION_KEY]
      }
    ]
  };
}
