import { SnakecaseSerializer, Serializer, setSnakecaseKey } from 'cerealizr';

// TODO: not using ingredients yet
const ingredientsSerializer = new SnakecaseSerializer();

const sideSerializer = new Serializer({
  descriptor: {
    menuableId: setSnakecaseKey(),
    menuableType: setSnakecaseKey(),
    name: setSnakecaseKey()
  },
  mapAllValues: false
});

const halvesSerializer = new Serializer({
  descriptor: {
    left: (key, value) => setSnakecaseKey(v => itemSerializer.serialize(v))(key, value), // eslint-disable-line no-use-before-define
    right: (key, value) => setSnakecaseKey(v => itemSerializer.serialize(v))(key, value) // eslint-disable-line no-use-before-define
  },
  mapAllValues: false
});

const itemSerializer = new Serializer({
  descriptor: {
    menuableType: setSnakecaseKey(),
    menuableId: setSnakecaseKey(),
    quantity: setSnakecaseKey(),
    name: setSnakecaseKey(),
    variantName: setSnakecaseKey(),
    withLoyalty: setSnakecaseKey(),
    ingredients: (key, value) => ({ [key]: ingredientsSerializer.serialize(value) }),
    halves: (key, value) => setSnakecaseKey(v => halvesSerializer.serialize(v))(key, value),
    side: setSnakecaseKey(value => sideSerializer.serialize(value)),
    ruleId: setSnakecaseKey()
  }
});

const offerSerializer = new Serializer({
  descriptor: {
    name: setSnakecaseKey(),
    description: setSnakecaseKey(),
    offerId: setSnakecaseKey(),
    quantity: setSnakecaseKey(),
    offerType: setSnakecaseKey(),
    groupProducts: setSnakecaseKey(value => ingredientsSerializer.serialize(value))
  }
});

const giftCardSerializer = new Serializer({
  descriptor: {
    name: setSnakecaseKey(),
    message: setSnakecaseKey(),
    amount: setSnakecaseKey(),
    price: setSnakecaseKey(),
    buyerEmail: setSnakecaseKey(),
    buyerName: setSnakecaseKey(),
    receiverEmail: setSnakecaseKey(),
    receiverName: setSnakecaseKey()
  }
});

const orderSerializer = new Serializer({
  descriptor: {
    acceptPoll: 'accept_poll',
    email: 'user_email',
    name: 'user_name',
    phone: 'user_phone',
    userAddress: setSnakecaseKey(),
    dispatchNote: setSnakecaseKey(),
    dispatchMethod: setSnakecaseKey(),
    apartmentDetails: setSnakecaseKey(),
    storeId: setSnakecaseKey(),
    coupon: (_, value) => ({ coupon: { code: value } }),
    tip: setSnakecaseKey(),
    onlinePayment: setSnakecaseKey(),
    paymentMethod: setSnakecaseKey(),
    requestedTime: setSnakecaseKey(),
    companyId: setSnakecaseKey(),
    hotspotId: setSnakecaseKey(),
    deliveryLatitude: setSnakecaseKey(),
    deliveryLongitude: setSnakecaseKey(),
    cookie: setSnakecaseKey(),
    giftCardCode: setSnakecaseKey(),
    freeGarlicSauces: setSnakecaseKey(),
    /* eslint-disable camelcase */
    items: (_, value) => ({ items_attributes: itemSerializer.serialize(value) }),
    offerItems: (_, value) => ({ offer_items_attributes: offerSerializer.serialize(value) }),
    giftCardItems: (_, value) => ({ gift_card_items_attributes: giftCardSerializer.serialize(value) }),
    privacyPolicies: 'privacy_policies',
    nif: 'nif'
    /* eslint-enable camelcase */
  }
});

const orderCallCenterSerializer = new Serializer({
  descriptor: {
    email: 'user_email',
    name: 'user_name',
    phone: 'user_phone',
    agentId: 'agent_id',
    agentName: 'agent_name',
    agentEmail: 'agent_email',
    userAddress: setSnakecaseKey(),
    dispatchNote: setSnakecaseKey(),
    dispatchMethod: setSnakecaseKey(),
    notes: setSnakecaseKey(),
    apartmentDetails: setSnakecaseKey(),
    storeId: setSnakecaseKey(),
    coupon: (_, value) => ({ coupon: { code: value } }),
    tip: setSnakecaseKey(),
    onlinePayment: setSnakecaseKey(),
    paymentMethod: setSnakecaseKey(),
    requestedTime: setSnakecaseKey(),
    companyId: setSnakecaseKey(),
    hotspotId: setSnakecaseKey(),
    deliveryLatitude: setSnakecaseKey(),
    deliveryLongitude: setSnakecaseKey(),
    /* eslint-disable camelcase */
    items: (_, value) => ({ items_attributes: itemSerializer.serialize(value) }),
    offerItems: (_, value) => ({ offer_items_attributes: offerSerializer.serialize(value) }),
    privacyPolicies: 'privacy_policies'
    /* eslint-enable camelcase */
  }
});

export const serializer = new SnakecaseSerializer({
  descriptor: {
    order: value => orderSerializer.serialize(value)
  }
});

export const callCenterSerializer = new SnakecaseSerializer({
  descriptor: {
    order: value => orderCallCenterSerializer.serialize(value)
  }
});
