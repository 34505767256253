/* eslint-disable no-negated-condition */
/* eslint-disable max-lines */
/* eslint-disable no-extra-boolean-cast */
import { t } from 'i18next';
import { create } from 'apisauce';

import PollingService from '../PollingService';

import store from '~redux/store';
import api from '~config/api';
import { retry } from '~utils/functions';
import { API_ERRORS } from '~constants/apiErrors';
import { COUNTRIES, CURRENT_COUNTRY_ID, TIP_ALLOWED } from '~constants/environment';
import { errorNormalizer } from '~config/apiTransforms';
import { ELECTRONIC_RECEIPT_ENABLED } from '~constants/features';
import { getShoppingCartTotal, onlyGiftCards } from '~models/shoppingCart';
import { PAYMENT_METHODS } from '~constants/paymentMethods';

import { serializer, callCenterSerializer } from './serializer';
import {
  INGENICO_BODY_REQUEST,
  OFFLINE_RETRY_TIMES,
  NO_DIRECTION,
  ORDER_ENDPOINT,
  ORDER_CALLCENTER_ENDPOINT,
  HEADERS
} from './constants';
import { normalizeGiftCards, normalizePaymentMethod } from './utils';

const privateEndpoints = {
  // eslint-disable-next-line complexity
  createOrder: ({
    user,
    shoppingCart,
    storeId,
    userAddress,
    dispatchMethod,
    dispatchNote,
    notes,
    apartmentDetails,
    onlinePayment,
    paymentMethod,
    coupon,
    tip,
    requestedTime,
    companyId,
    hotspotId,
    agent,
    deliveryLatitude,
    deliveryLongitude,
    awinKey,
    redeemedCode,
    acceptPoll,
    privacyPolicies,
    freeGarlicSauces,
    cardId,
    ingenico3DSv2,
    nif
  }) => {
    const normalizedPaymentMethod = normalizePaymentMethod(paymentMethod, onlinePayment);
    if (!shoppingCart) {
      return { error: t('Order:noShoppingCart') };
    }

    const shoppingCartClone = { ...shoppingCart };

    shoppingCartClone.items = shoppingCartClone.items.map(item => ({
      ...item,
      withLoyalty: !!item.pointsSpent
    }));

    const totalWithTip = shoppingCartClone.total + (tip ?? 0);
    const isOnlinePaymentRequired =
      CURRENT_COUNTRY_ID === COUNTRIES.cl
        ? totalWithTip > 1 && onlinePayment
        : totalWithTip !== 0 && onlinePayment;

    const giftCardIsOverTotal =
      redeemedCode?.useGiftCard && redeemedCode?.amount >= getShoppingCartTotal(shoppingCart) && !tip;

    const paymentInformation = {
      ...(!giftCardIsOverTotal && {
        paymentMethod:
          !isOnlinePaymentRequired && onlinePayment ? PAYMENT_METHODS.cash : normalizedPaymentMethod,
        ...(TIP_ALLOWED && { tip })
      }),
      ...(redeemedCode?.isGiftCard && redeemedCode?.useGiftCard && { giftCardCode: redeemedCode?.code })
    };

    const sendCompanyId = ELECTRONIC_RECEIPT_ENABLED && !onlyGiftCards(shoppingCart.items);

    const body = {
      order: {
        apartmentDetails,
        ...user,
        ...agent,
        items: shoppingCartClone?.items?.filter(item => item?.menuableType),
        offerItems: shoppingCartClone?.items?.filter(item => item?.offerType),
        giftCardItems: normalizeGiftCards(
          shoppingCartClone?.items?.filter(item => item?.isGiftCard),
          user
        ),
        storeId,
        userAddress: userAddress ? userAddress : NO_DIRECTION,
        dispatchMethod,
        dispatchNote,
        notes,
        ...(freeGarlicSauces && { freeGarlicSauces }),
        ...(coupon && { coupon }),
        ...(sendCompanyId && { companyId }),
        ...(hotspotId && { hotspotId }),
        ...paymentInformation,
        deliveryLatitude,
        deliveryLongitude,
        acceptPoll: !onlyGiftCards(shoppingCart.items) && acceptPoll,
        privacyPolicies,
        ...(cardId && { cardId }),
        ...(ingenico3DSv2 && { ingenico3DSv2 }),
        ...(nif && { nif })
      },
      ...(awinKey && { cookie: awinKey }),
      ...(!giftCardIsOverTotal && { onlinePayment: isOnlinePaymentRequired }),
      requestedTime
    };
    // agent correspond to a callcenter agent, so, if in order body we get an agent, we know that this order comes from callcenter agent
    return agent
      ? api.post(`${ORDER_CALLCENTER_ENDPOINT}#${user.externalId}`, callCenterSerializer.serialize(body))
      : api.post(ORDER_ENDPOINT, serializer.serialize(body));
  },
  checkOrder: ({ token }) => {
    const { auth } = store.getState();
    const temporalApi = create({
      baseURL: process.env.REACT_APP_CORE_HOST
    });

    temporalApi.addResponseTransform(errorNormalizer);

    return temporalApi.get(
      '/v1/orders_job',
      {},
      {
        headers: {
          'X-JOB-AUTHORIZATION': token,
          [HEADERS.USER_EMAIL]: auth.guestUser?.email || auth.currentUser?.email
        }
      }
    );
  },
  powertranzStartPayment: ({ orderId, cardId, cardCode, cardNumber, expiration }) =>
    api.post(
      `/v1/orders/${orderId}/payments/powertranz`,
      serializer.serialize({ ...(!!cardId ? { cardId } : { cardNumber }), cvc: cardCode, expiration })
    ),
  mercantilStartPayment: ({ orderId, cardId, cardCode, cardNumber, cardToken }) =>
    api.post(
      `/v1/orders/${orderId}/payments/mercantil`,
      serializer.serialize({ ...(!!cardId ? { cardId } : { cardNumber, cardToken }), cvc: cardCode })
    ),
  oneClickStartPayment: ({ orderId, cardId }) =>
    api.post(`/v1/orders/${orderId}/payments/oneclick`, serializer.serialize({ cardId }))
};

const OrderService = {
  createOrder: order => {
    const isOfflinePayment = !order.onlinePayment;
    const retriesLeft = isOfflinePayment ? OFFLINE_RETRY_TIMES : 0;

    const request = () =>
      PollingService.poll({
        request: privateEndpoints.createOrder(order),
        polleableService: privateEndpoints.checkOrder,
        shouldRetry: response => {
          const { code } = response.data?.errors || response.data || { code: API_ERRORS.SERVICE_ERROR };
          return code === API_ERRORS.SERVICE_ERROR;
        }
      });
    return retry(request, retriesLeft);
  },
  getCurrentOrder: email =>
    PollingService.poll({
      request: undefined,
      polleableService: api.get('v1/orders/last', null, {
        headers: {
          [HEADERS.USER_EMAIL]: email
        }
      })
    }),
  getActiveOrders: email =>
    PollingService.poll({
      request: undefined,
      polleableService: api.get('v1/orders/active', null, {
        headers: {
          [HEADERS.USER_EMAIL]: email
        }
      })
    }),
  getPastOrders: (page = 1) => api.get('v1/orders', { page }),
  powertranzStartPayment: ({ orderId, cardId, cardCode, cardNumber, expiration }) =>
    PollingService.poll({
      request: privateEndpoints.powertranzStartPayment({ orderId, cardId, cardCode, cardNumber, expiration }),
      polleableService: privateEndpoints.checkOrder
    }),
  mercantilStartPayment: ({ orderId, cardId, cardCode, cardNumber, cardToken }) =>
    PollingService.poll({
      request: privateEndpoints.mercantilStartPayment({ orderId, cardId, cardCode, cardNumber, cardToken }),
      polleableService: privateEndpoints.checkOrder
    }),
  oneclickStartPayment: ({ orderId, cardId }) =>
    PollingService.poll({
      request: privateEndpoints.oneClickStartPayment({ orderId, cardId }),
      polleableService: privateEndpoints.checkOrder
    }),
  ingenicoStartPayment: ({ orderId, createCard }) =>
    api.post(
      `/v1/orders/${orderId}/payments/ingenico`,
      serializer.serialize({ ...INGENICO_BODY_REQUEST, createCard })
    ),
  ingenicoCardStartPayment: ({ orderId, cardId, ingenico3DSv2 }) =>
    api.post(
      `/v2/orders/${orderId}/payments/ingenico/create_oneclick`,
      serializer.serialize({ cardId, ingenicoParameters: ingenico3DSv2, ...INGENICO_BODY_REQUEST })
    ),
  webPayStartPayment: orderId =>
    PollingService.poll({
      request: api.post(`/v2/orders/${orderId}/payments/webpay`),
      polleableService: privateEndpoints.checkOrder
    }),
  mercadoPagoStartPayment: ({ orderId, body }) =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/mercado_pago`, serializer.serialize(body)),
      polleableService: privateEndpoints.checkOrder
    }),
  applePayStartPayment: orderId =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/apple_pay`),
      polleableService: privateEndpoints.checkOrder
    }),
  googlePayStartPayment: orderId =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/google_pay`),
      polleableService: privateEndpoints.checkOrder
    }),
  directClickToPayStartPayment: ({ orderId, deviceData }) =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/direct_click_to_pay`, deviceData),
      polleableService: privateEndpoints.checkOrder
    }),
  sodexoStartPayment: orderId =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/sodexo`),
      polleableService: privateEndpoints.checkOrder
    }),
  fpayStartPayment: orderId =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/fpay`),
      polleableService: privateEndpoints.checkOrder
    }),
  bizumStartPayment: orderId =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/bizum`),
      polleableService: privateEndpoints.checkOrder
    }),
  paypalStartPayment: orderId =>
    PollingService.poll({
      request: api.post(`/v1/orders/${orderId}/payments/paypal`),
      polleableService: privateEndpoints.checkOrder
    }),
  sinpeMovilStartPayment: ({ orderId, clientDocumentId }) =>
    api.post(
      `/v1/orders/${orderId}/payments/sinpe_movil/notification?client_id=${clientDocumentId}&source_news=webhooks&reference_id=-1`
    ),
  creditCardConfirm: token => PollingService.singlePoll(privateEndpoints.checkOrder, { token }),
  getOrderPoll: async ({ orderId, userId }) => {
    api.setHeaders({ 'X-USERID': userId });
    const response = await api.get(`/v1/orders/${orderId}/poll`);
    delete api.headers['X-USERID'];
    return response;
  },
  sendOrderPoll: async ({ values, userId }) => {
    api.setHeaders({ 'X-USERID': userId });
    const response = await api.post('/v1/answers/mass_create', serializer.serialize(values));

    delete api.headers['X-USERID'];

    return response;
  },
  addPaymentCard: ({ card, isMercantil }) =>
    PollingService.poll({
      request: privateEndpoints.addPaymentCard({ card, isMercantil }),
      polleableService: privateEndpoints.checkUsers
    }),
  getPaymentCards: (isMercantil = false) =>
    api.get(`/v1/users/cards${isMercantil ? '?provider=mercantil' : ''}`),
  deletePaymentCard: id => api.delete(`/v1/users/cards/powertranz/${id}`),
  getOrderSettings: () => api.get('/v1/orders_settings'),
  getCoreSettings: () => api.get('/v1/core_settings'),
  getLastActiveOrder: email =>
    api.get('/v1/orders/active_order', null, {
      headers: {
        [HEADERS.USER_EMAIL]: email
      }
    })
};

export default OrderService;
