/* eslint-disable no-console */
export const showCookiebotDialog = () => {
  if (window.CookieConsent && typeof window.CookieConsent.renew === 'function') {
    window.CookieConsent.renew();
  } else if (window.Cookiebot && typeof window.Cookiebot.renew === 'function') {
    window.Cookiebot.renew();
  } else {
    console.error('Cookiebot no está cargado o la función de renovación no está disponible.');
  }
};
