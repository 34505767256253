import i18next from 'i18next';

i18next.addResources('es_CL', 'Field', {
  email: 'El correo',
  password: 'Contraseña',
  coupon: 'Cupón',
  name: 'El nombre',
  onlyNameLowCase: 'nombre',
  phone: 'Tu número de teléfono',
  insertedPhone: 'El teléfono introducido',
  apartment: 'Departamento / Oficina / Casa',
  invalid: '{{ field }} introducido no es válido',
  required: '{{ field }} es necesario',
  min: '{{field}} debe tener al menos {{min}} caracteres',
  max: '{{field}} debe tener máximo {{max}} caracteres',
  length: '{{field}} debe tener {{length}} dígitos',
  matches: 'El formato del {{field}} no es valido',
  spaceAtBeginOrEnd: 'No se permiten espacios ni al principio ni al final',
  generalName: 'Este valor',
  codeCountry: 'El {{field}} debe incluir el código del país ({{code}})',
  passwordFormat: 'Debe contener mayúsculas, minúsculas, números y un caracter especial',
  validPasswordFormat: 'Formato de contraseña válido',
  validPasswordFormatText:
    'Debe contener: -Una letra minúscula -Una letra mayúscula -Un número -Un caracter especial -Mínimo 8 caracteres -Máximo 15 caracteres',
  cardNumber: 'Debe contener 16 dígitos',
  cardCode: 'CVC',
  termsAndConditions: 'Debe aceptar los términos y condiciones',
  privacyPolicies: 'Este campo es requerido',
  optionRequired: 'Es necesario elegir una opción.',
  code: 'El código'
});

i18next.addResources('es_CL', 'CognitoErrors', {
  NotAuthorizedException: 'Limite de intentos excedidos',
  UsernameExistsException: 'Una cuenta con el email dado ya existe',
  UserNotFoundException: 'El usuario no existe',
  InvalidParameterException: 'Formato no válido',
  InvalidParameterValue: 'Valor del parámetro no válido',
  InvalidPasswordException: 'Contraseña demasiado sencilla. Elige una más segura',
  CodeDeliveryFailureException: 'Error al enviar el código de verificación',
  CodeMismatchException: 'Código incorrecto',
  LimitExceededException: 'Limite de intentos excedidos',
  NetworkError: 'Error de conexión',
  UserLambdaValidationException: 'Hay un error en los datos ingresados, verifíquelos e intente nuevamente',
  PhoneTaken: 'El teléfono ingresado se encuentra en uso',
  NotFound: 'Hubo un error con la cuenta, por favor intenta de nuevo.',
  UserNotFound: 'Usuario no encontrado.'
});

i18next.addResources('es_CL', 'RequestErrors', {
  STORE_CLOSED: 'El local se encuentra cerrado en este momento',
  NOT_AUTHORIZED: 'Ha ocurrido un error con su sesión. Por favor, intenta iniciar sesión nuevamente',
  DELIVERY_MINIMUM_NOT_REACHED: 'El valor del pedido es menor al mínimo requerido',
  ORDER_CREATION_BLOCKED: 'No se pudo generar la orden porque hay una en curso',
  NETWORK_ERROR: 'Ha ocurrido un error, por favor intente nuevamente más tarde',
  Unauthorized: 'Ha ocurrido un error con su sesión. Por favor, intenta iniciar sesión nuevamente',
  timeout: 'El pedido ha superado el tiempo máximo. Por favor intente nuevamente en unos minutos.'
});

i18next.addResources('es_ES', 'Field', {
  email: 'El email',
  password: 'Contraseña',
  coupon: 'Cupón',
  name: 'El nombre',
  onlyNameLowCase: 'nombre',
  phone: 'Tu número de teléfono',
  insertedPhone: 'El teléfono introducido',
  apartment: 'Departamento / Oficina / Casa',
  invalid: '{{ field }} introducido no es válido',
  required: '{{ field }} es necesario',
  min: '{{field}} debe tener al menos {{min}} caracteres',
  max: '{{field}} debe tener máximo {{max}} caracteres',
  matches: 'El formato del {{field}} no es valido',
  spaceAtBeginOrEnd: 'No se permiten espacios ni al principio ni al final',
  generalName: 'Este valor',
  codeCountry: 'El {{field}} debe incluir el código del país ({{code}})',
  length: '{{field}} debe tener {{length}} caracteres',
  passwordFormat: 'Debe contener mayúsculas, minúsculas, números y un carácter especial',
  validPasswordFormat: 'Formato de contraseña válido',
  validPasswordFormatText:
    'Debe contener: -Una letra minúscula -Una letra mayúscula -Un número -Un caracter especial -Mínimo 8 caracteres -Máximo 15 caracteres'
});

i18next.addResources('es_ES', 'CognitoErrors', {
  NotAuthorizedException: 'Limite de intentos excedidos',
  UsernameExistsException: 'Una cuenta con el email dado ya existe',
  UserNotFoundException: 'El usuario no existe',
  InvalidParameterException: 'Formato no válido',
  InvalidParameterValue: 'Valor del parámetro no válido',
  InvalidPasswordException: 'Contraseña demasiado sencilla. Elige una más segura',
  CodeDeliveryFailureException: 'Error al enviar el código de verificación',
  CodeMismatchException: 'Código incorrecto',
  LimitExceededException: 'Limite de intentos excedidos',
  NetworkError: 'Error de conexión',
  UserLambdaValidationException: 'Hay un error en los datos ingresados, verifíquelos e intente nuevamente',
  PhoneTaken: 'El teléfono ingresado se encuentra en uso',
  NotFound: 'Hubo un error con la cuenta, por favor intenta de nuevo.',
  UserNotFound: 'Usuario no encontrado.'
});

i18next.addResources('es_ES', 'RequestErrors', {
  STORE_CLOSED: 'El local se encuentra cerrado en este momento',
  NOT_AUTHORIZED: 'Ha ocurrido un error con su sesión. Por favor, intenta iniciar sesión nuevamente',
  DELIVERY_MINIMUM_NOT_REACHED: 'El valor del pedido es menor al mínimo requerido',
  ORDER_CREATION_BLOCKED: 'No se pudo generar la orden porque hay una en curso',
  NETWORK_ERROR: 'Ha ocurrido un error, por favor intente nuevamente más tarde',
  Unauthorized: 'Ha ocurrido un error con su sesión. Por favor, intenta iniciar sesión nuevamente',
  timeout: 'El pedido ha superado el tiempo máximo. Por favor intente nuevamente en unos minutos.'
});

i18next.addResources('pt_PT', 'Field', {
  email: 'O e-mail',
  password: 'Senha',
  coupon: 'Cupão',
  name: 'O nome',
  phone: 'O teu telefone',
  onlyNameLowCase: 'nome',
  insertedPhone: 'O número de telefone inserido',
  apartment: 'Departamento / Escritório / Casa',
  invalid: '{{ field }} inserido não é válido',
  required: '{{ field }} é obrigatório',
  min: '{{field}} deve ter pelo menos {{min}} caracteres',
  max: '{{field}} deve ter no máximo {{max}} caracteres',
  matches: 'O formato do {{field}} é inválido',
  spaceAtBeginOrEnd: 'Não são permitidos espaços no início e no final',
  generalName: 'Este valor',
  codeCountry: 'Ele {{field}} você deve incluir o código do país ({{code}})',
  length: '{{field}} deve ter {{length}} caracteres',
  passwordFormat: 'Deve conter letras maiúsculas, minúsculas, números e um caractere especial',
  validPasswordFormat: 'Formato de senha válido',
  validPasswordFormatText:
    'Deve conter: -Uma letra minúscula -A letra maiúscula -Um número -Um caractere especial -Mínimo de 8 caracteres -Máximo 15 caracteres',
  cardNumber: 'Deve conter 16 dígitos',
  termsAndConditions: 'Você deve aceitar os Termos e Condições',
  privacyPolicies: 'Você deve aceitar a política de privacidade e as condições gerais de venda',
  nif: 'NIF'
});

i18next.addResources('pt_PT', 'CognitoErrors', {
  NotAuthorizedException: 'Limite de tentativas excedido',
  UsernameExistsException: 'Uma conta com o email fornecido já existe',
  UserNotFoundException: 'O usuário não existe',
  InvalidParameterException: 'Formato inválido',
  InvalidParameterValue: 'Valor do parâmetro inválido',
  InvalidPasswordException: 'Senha muito simples. Escolha uma mais segura',
  CodeDeliveryFailureException: 'Erro ao enviar o código de verificação',
  CodeMismatchException: 'Código errado',
  LimitExceededException: 'Limite de tentativas excedido',
  NetworkError: 'Erro de conexão',
  UserLambdaValidationException: 'Há um erro nos dados inseridos, verificá-los e tentar novamente',
  PhoneTaken: 'O telefone inserido está em uso',
  NotFound: 'Houve um erro com a conta, por favor, tente novamente.',
  UserNotFound: 'Usuário não encontrado.'
});

i18next.addResources('pt_PT', 'RequestErrors', {
  STORE_CLOSED: 'O lugar está atualmente fechado',
  NOT_AUTHORIZED: 'Ocorreu um erro com a sua sessão. Por favor tente logar novamente',
  DELIVERY_MINIMUM_NOT_REACHED: 'O valor do pedido é menor que o mínimo exigido',
  ORDER_CREATION_BLOCKED: 'O pedido não pôde ser gerado porque está em andamento',
  NETWORK_ERROR: 'Ocorreu um erro, por favor tente novamente mais tarde',
  Unauthorized: 'Ocorreu um erro com a sua sessão. Por favor tente logar novamente',
  timeout: 'O pedido excedeu o tempo máximo. Por favor, tente novamente em alguns minutos..'
});
