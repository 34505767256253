/* eslint-disable max-lines */

import { t } from 'i18next';

import { PRODUCT_CATEGORIES } from '../../screens/Home/components/Menu/constants';

import { CLIENT_PAGES } from '~constants/pages';
import { BASE_URL_ROOT_DOMAIN, CURRENT_COUNTRY_ID } from '~constants/environment';
import { ANALYTICS_IDS } from '~constants/analytics';
import logoSlogan from '~assets/logo/logo-slogan.svg';
import logoSloganPT from '~assets/logo/logo-slogan-pt.svg';
import { isGDPRCountry } from '~utils/cookieSettings';

import styles from './styles.module.scss';
import { showCookiebotDialog } from './utils';

export const CURRENT_YEAR = new Date().getFullYear();

const titleGroupStyle = `text-medium-condensed ${styles.titleGroup}`;

const groupStyle = `column top ${styles.groupLinks}`;

const linkStyle = `text-select ${styles.linkFooter}`;

const defaultLegalLinks = [
  {
    text: t('Footer:conditions'),
    to: CLIENT_PAGES.TERMS.path,
    style: linkStyle,
    rel: 'nofollow',
    id: ANALYTICS_IDS.links.footer.conditions
  },
  {
    text: t('Footer:privacyPolicy'),
    to: CLIENT_PAGES.PRIVACY_POLICY.path,
    style: linkStyle,
    rel: 'nofollow',
    id: ANALYTICS_IDS.links.footer.privacyPolicy
  },
  {
    text: t('Footer:ethicalCode'),
    href: 'https://cdn.papajohns.cl/rules/codigo_etico_y_conducta_profesional_-_DFSI.pdf',
    style: linkStyle,
    rel: 'nofollow',
    id: ANALYTICS_IDS.links.footer.ethicalCode
  }
];

const linkBlog = BASE_URL_ROOT_DOMAIN
  ? [
      {
        text: t('Footer:blog'),
        href: `https://blog.${BASE_URL_ROOT_DOMAIN}`,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.blog
      }
    ]
  : [];

const linkCookieBotPrompt = isGDPRCountry()
  ? [
      {
        text: t('Footer:cookieSettings'),
        action: showCookiebotDialog,
        style: linkStyle
      }
    ]
  : [];

const MENU = [
  [PRODUCT_CATEGORIES.PROMO, {}],
  [PRODUCT_CATEGORIES.PIZZA, {}],
  [PRODUCT_CATEGORIES.SIDE, {}],
  [PRODUCT_CATEGORIES.DRINK, {}],
  [PRODUCT_CATEGORIES.DESSERT, {}],
  [PRODUCT_CATEGORIES.EXTRA, {}]
];
const MENU_LINKS = MENU.map(([category, config]) => ({
  text: t(`Menu:${category}`),
  style: linkStyle,
  href: t(`Footer:${category}sPath`),
  sameTab: false,
  ...config
}));

const FOOTER_LINKS_CL = [
  {
    titleGroup: t('Footer:titleGroup1'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:aboutUs'),
        to: CLIENT_PAGES.ABOUT_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.aboutUs
      },
      {
        text: t('Footer:ingredients'),
        to: CLIENT_PAGES.INGREDIENTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.ingredients
      },
      {
        text: t('Footer:companyInternational'),
        href: 'https://www.papajohns.com/international',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.companyInternational
      },
      {
        text: t('Footer:freeChickens'),
        to: CLIENT_PAGES.FREE_CHICKENS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.freeChickens
      },
      {
        text: t('Footer:betterWorld'),
        href: 'http://mejormundo.cl/',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.betterWorld
      },
      {
        text: t('Footer:storesLocation'),
        href: CLIENT_PAGES.STORES.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.stores,
        sameTab: true
      },
      ...linkBlog
    ]
  },
  {
    titleGroup: t('Footer:titleGroup3'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:helpCenter'),
        to: CLIENT_PAGES.HELPCENTER.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.helpCenter
      },
      {
        text: t('Footer:contact'),
        to: CLIENT_PAGES.CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contact
      },
      {
        text: t('Footer:sacNumber'),
        href: 'tel: 6003905000',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contact
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup4'),
    titleGroupStyle,
    groupStyle,
    links: [
      ...defaultLegalLinks,
      {
        text: t('Footer:ethicalChannel'),
        href: 'https://www.canaldedenunciaspapajohnschile.com/',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.ethicalChannel
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup5'),
    titleGroupStyle,
    groupStyle,
    links: MENU_LINKS
  },
  {
    titleGroup: t('Footer:titleGroup6'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:joinTheProgram'),
        to: CLIENT_PAGES.PAPA_POINTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.joinTheProgram
      }
    ]
  }
];

const FOOTER_LINKS_CR = [
  {
    titleGroup: t('Footer:titleGroup1'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:aboutUs'),
        to: CLIENT_PAGES.ABOUT_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.aboutUs
      },
      {
        text: t('Footer:ingredients'),
        to: CLIENT_PAGES.INGREDIENTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.ingredients
      },
      {
        text: t('Footer:workWithUs'),
        href: CLIENT_PAGES.WORK_WITH_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.workWithUs
      },
      {
        text: t('Footer:companyInternational'),
        href: 'https://www.papajohns.com/international',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.companyInternational
      },
      {
        text: t('Footer:storesLocation'),
        href: CLIENT_PAGES.STORES.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.stores,
        sameTab: true
      },
      ...linkBlog
    ]
  },
  {
    titleGroup: t('Footer:titleGroup3'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:helpCenter'),
        to: CLIENT_PAGES.HELPCENTER.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.helpCenter
      },
      {
        text: t('Footer:contact'),
        to: CLIENT_PAGES.CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contact
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup4'),
    titleGroupStyle,
    groupStyle,
    links: [...defaultLegalLinks]
  },
  {
    titleGroup: t('Footer:titleGroup5'),
    titleGroupStyle,
    groupStyle,
    links: MENU_LINKS
  },
  {
    titleGroup: t('Footer:titleGroup6'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:joinTheProgram'),
        to: CLIENT_PAGES.PAPA_POINTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.joinTheProgram
      }
    ]
  }
];

const FOOTER_LINKS_ES = [
  {
    titleGroup: t('Footer:titleGroup1'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:aboutUs'),
        to: CLIENT_PAGES.ABOUT_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.aboutUs
      },
      {
        text: t('Footer:workWithUs'),
        href: 'http://www.empleopapajohns.es',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.workWithUs
      },
      {
        text: t('Footer:companyInternational'),
        href: 'https://www.papajohns.com/international',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.companyInternational
      },
      {
        text: t('Footer:storesLocation'),
        href: CLIENT_PAGES.STORES.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.stores,
        sameTab: true
      },
      {
        text: t('Footer:helpCenter'),
        to: CLIENT_PAGES.HELPCENTER.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.helpCenter
      },
      {
        text: t('Footer:contact'),
        to: CLIENT_PAGES.CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contact
      },
      ...linkCookieBotPrompt
    ]
  },
  {
    titleGroup: t('Footer:titleGroup2'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:ingredients'),
        to: CLIENT_PAGES.INGREDIENTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.ingredients
      },
      {
        text: t('Footer:allergens'),
        href: CLIENT_PAGES.ALLERGENS.path,
        rel: 'nofollow',
        style: linkStyle
      },
      {
        text: t('Footer:nutritionalInformation'),
        href: CLIENT_PAGES.NUTRITIONAL_INFO.path,
        rel: 'nofollow',
        style: linkStyle
      },
      {
        text: t('Footer:glutenFree'),
        href: CLIENT_PAGES.GLUTEN_FREE.path,
        rel: 'nofollow',
        style: linkStyle
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup7'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:textInformationFranchises'),
        to: CLIENT_PAGES.FRANCHISES_INFORMATION.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.franchises
      },
      {
        text: t('Footer:textContactFormFranchises'),
        to: CLIENT_PAGES.FRANCHISES_CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contactFormfranchises
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup5'),
    titleGroupStyle,
    groupStyle,
    links: MENU_LINKS
  },
  {
    titleGroup: t('Footer:titleGroup4'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:legalDisclaimer'),
        to: CLIENT_PAGES.LEGAL_DISCLAIMER.path,
        rel: 'nofollow',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.legalDisclaimer
      },
      ...defaultLegalLinks,
      {
        text: t('Footer:complaintsChannel'),
        href: 'https://compliance.legalsending.com/canal/?C=48604809019043680',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.complaintsChannel
      },
      {
        text: t('Footer:cookiesPolicy'),
        to: CLIENT_PAGES.COOKIES_POLICY.path,
        rel: 'nofollow',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.cookiesPolicy
      }
    ]
  }
];

const FOOTER_LINKS_PA = [
  {
    titleGroup: t('Footer:titleGroup1'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:aboutUs'),
        to: CLIENT_PAGES.ABOUT_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.aboutUs
      },
      {
        text: t('Footer:ingredients'),
        to: CLIENT_PAGES.INGREDIENTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.ingredients
      },
      {
        text: t('Footer:workWithUs'),
        href: CLIENT_PAGES.WORK_WITH_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.workWithUs
      },
      {
        text: t('Footer:companyInternational'),
        href: 'https://www.papajohns.com/international',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.companyInternational
      },
      {
        text: t('Footer:storesLocation'),
        href: CLIENT_PAGES.STORES.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.stores,
        sameTab: true
      },
      ...linkBlog
    ]
  },
  {
    titleGroup: t('Footer:titleGroup3'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:helpCenter'),
        to: CLIENT_PAGES.HELPCENTER.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.helpCenter
      },
      {
        text: t('Footer:contact'),
        to: CLIENT_PAGES.CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contact
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup4'),
    titleGroupStyle,
    groupStyle,
    links: [...defaultLegalLinks]
  },
  {
    titleGroup: t('Footer:titleGroup5'),
    titleGroupStyle,
    groupStyle,
    links: MENU_LINKS
  },
  {
    titleGroup: t('Footer:titleGroup6'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:joinTheProgram'),
        to: CLIENT_PAGES.PAPA_POINTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.joinTheProgram
      }
    ]
  }
];

const FOOTER_LINKS_PT = [
  {
    titleGroup: t('Footer:titleGroup1'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:aboutUs'),
        to: CLIENT_PAGES.ABOUT_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.aboutUs
      },
      {
        text: t('Footer:workWithUs'),
        to: CLIENT_PAGES.WORK_WITH_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.workWithUs
      },
      {
        text: t('Footer:ingredients'),
        to: CLIENT_PAGES.INGREDIENTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.ingredients
      },
      {
        text: t('Footer:companyInternational'),
        href: 'https://www.papajohns.com/international',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.companyInternational
      },
      {
        text: t('Footer:storesLocation'),
        href: CLIENT_PAGES.STORES.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.stores,
        sameTab: true
      },
      ...linkCookieBotPrompt
    ]
  },
  {
    titleGroup: t('Footer:titleGroup3'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:helpCenter'),
        to: CLIENT_PAGES.HELPCENTER.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.helpCenter
      },
      {
        text: t('Footer:contact'),
        to: CLIENT_PAGES.CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contact
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup7'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:textInformationFranchises'),
        to: CLIENT_PAGES.FRANCHISES_INFORMATION.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.franchises
      },
      {
        text: t('Footer:textContactFormFranchises'),
        to: CLIENT_PAGES.FRANCHISES_CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contactFormfranchises
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup5'),
    titleGroupStyle,
    groupStyle,
    links: MENU_LINKS
  },
  {
    titleGroup: t('Footer:titleGroup4'),
    titleGroupStyle,
    groupStyle,
    links: [
      ...defaultLegalLinks,
      {
        text: t('Footer:complaintsChannel'),
        href: 'https://compliance.legalsending.com/canal/?C=48604809019043680',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.complaintsChannel
      }
    ]
  }
];

const FOOTER_LINKS_GT = [
  {
    titleGroup: t('Footer:titleGroup1'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:aboutUs'),
        to: CLIENT_PAGES.ABOUT_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.aboutUs
      },
      {
        text: t('Footer:ingredients'),
        to: CLIENT_PAGES.INGREDIENTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.ingredients
      },
      {
        text: t('Footer:workWithUs'),
        href: CLIENT_PAGES.WORK_WITH_US.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.workWithUs
      },
      {
        text: t('Footer:companyInternational'),
        href: 'https://www.papajohns.com/international',
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.companyInternational
      },
      {
        text: t('Footer:storesLocation'),
        href: CLIENT_PAGES.STORES.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.stores,
        sameTab: true
      },
      ...linkBlog
    ]
  },
  {
    titleGroup: t('Footer:titleGroup3'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:helpCenter'),
        to: CLIENT_PAGES.HELPCENTER.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.helpCenter
      },
      {
        text: t('Footer:contact'),
        to: CLIENT_PAGES.CONTACT_FORM.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.contact
      }
    ]
  },
  {
    titleGroup: t('Footer:titleGroup4'),
    titleGroupStyle,
    groupStyle,
    links: [...defaultLegalLinks]
  },
  {
    titleGroup: t('Footer:titleGroup5'),
    titleGroupStyle,
    groupStyle,
    links: MENU_LINKS
  },
  {
    titleGroup: t('Footer:titleGroup6'),
    titleGroupStyle,
    groupStyle,
    links: [
      {
        text: t('Footer:joinTheProgram'),
        to: CLIENT_PAGES.PAPA_POINTS.path,
        style: linkStyle,
        id: ANALYTICS_IDS.links.footer.joinTheProgram
      }
    ]
  }
];

export const FOOTER_LOGO = {
  pt: logoSloganPT,
  default: logoSlogan
};

export const FOOTER_LINKS = {
  cl: FOOTER_LINKS_CL,
  es: FOOTER_LINKS_ES,
  pt: FOOTER_LINKS_PT,
  pa: FOOTER_LINKS_PA,
  cr: FOOTER_LINKS_CR,
  gt: FOOTER_LINKS_GT
};

const SOCIAL_LINKS_BY_COUNTRY = {
  cl: {
    instagram: 'https://www.instagram.com/papajohnschile/?hl=es-la',
    facebook: 'https://www.facebook.com/PapaJohnsChile',
    twitter: null,
    tiktok: 'https://www.tiktok.com/@papajohns.chile',
    youtube: null
  },
  es: {
    instagram: 'https://www.instagram.com/papajohns_es/?hl=es-es/',
    facebook: 'https://www.facebook.com/papajohnsespana',
    tiktok: 'https://www.tiktok.com/@papajohns_es?_t=8aRfKvVeCiU&_r=1',
    twitter: 'https://twitter.com/PapaJohns_ES?s=21&t=PxYHgvQH2dmP4khOv9Mgng',
    youtube: 'https://youtube.com/@PapaJohns_ES'
  },
  pt: {
    instagram: 'https://www.instagram.com/papajohns_pt/?hl=pt-pt/',
    facebook: 'https://www.facebook.com/PapaJohnsPortugal/',
    twitter: null,
    youtube: null
  },
  pa: {
    instagram: 'https://www.instagram.com/papajohnspanama/?hl=es-es/',
    facebook: 'https://www.facebook.com/PapaJohnsPanama',
    twitter: null,
    youtube: null
  },
  cr: {
    instagram: 'https://www.instagram.com/papajohnscr/?hl=es-es/',
    facebook: 'https://www.facebook.com/papajohnscr',
    twitter: null,
    youtube: null
  },
  gt: {
    instagram: 'https://www.instagram.com/papajohnsgt/',
    facebook: 'https://www.facebook.com/papajohnsgt',
    twitter: 'https://twitter.com/papajohnsgt',
    youtube: null
  }
};

export const SOCIAL_LINKS = SOCIAL_LINKS_BY_COUNTRY[CURRENT_COUNTRY_ID];
