import React from 'react';
import { Helmet } from 'react-helmet';

import { getStructuredDataOrg } from '~constants/structuredData';

export function StructuredData() {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(getStructuredDataOrg())}</script>
    </Helmet>
  );
}
